<template>
  <section class="login">
  </section>
</template>

<script>
import { mapState } from 'vuex'
import organizationApi from "@/apis/organization";

export default {
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      isLogin: (state) => state.auth.isLogin,
    }),
  },
  async mounted() {
    this.init()
  },
  methods: {
    async init() {
      const alreadyLoginOtherOrg = this.isLogin && this.user && this.user.current_organization.code !== this.$route.params.org_code;
      if (alreadyLoginOtherOrg) {
        this.loginByTokenWithAlreadyLoginOtherOrg();
      } else {
        this.loginByToken();
      }
    },
    async loginByTokenWithAlreadyLoginOtherOrg() {
      const organization = await this.fetchOrganization();
      console.log('org', organization)

      const result = await this.$swal.fire({
        title: '錯誤',
        html: `
          <span>已經登入 ${this.user.current_organization.name}</span><br>
          <span>登入帳號：${this.user.employee_code} </span><br>
          <span>登入角色：${this.user.role_name} </span><br>
          <span>無 ${organization.name} 權限</span><br>
          <span>請問是否用新帳號登入 ${organization.name}</span> ?
        `,
        type: 'error',
        confirmButtonText: '新帳號登入',
        showCancelButton: true,
        cancelButtonText: '回到後台',
      });

      if (result.value) {
        await this.$store.dispatch('auth/logout');
        this.loginByToken();
      } else {
        return this.$router.push({
          name: 'DashboardHome',
          params: {
            org_code: this.user.current_organization.code
          }
        })
      }
    },
    async fetchOrganization() {
      try {
        let response = await organizationApi.get(this.$route.params.org_code)

        if (response.status === 200 && response.data.data !== null) {
          return response.data.data
        }
      } catch (e) {
        console.log(e)
        alert('取得組織資料失敗')
      }
    },
    async loginByToken() {
      await this.$store
        .dispatch("auth/loginByToken", { token: this.$route.query.token, org_code: this.$route.params.org_code })
        .then(() => {
          this.$router.push({
            name: "DashboardJump",
            params: { org_code: this.$route.params.org_code },
          });
        })
        .catch((error) => {
          console.error(error);
          this.$swal("失敗", error.response.data.message, "error").then(() => {
            this.$router.push({ name: 'AuthLogin' })
          });
        });
    },
  },
};
</script>
